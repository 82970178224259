import { render, staticRenderFns } from "./videoDetail.vue?vue&type=template&id=3d11e9bf&scoped=true&"
import script from "./videoDetail.vue?vue&type=script&lang=js&"
export * from "./videoDetail.vue?vue&type=script&lang=js&"
import style0 from "./videoDetail.vue?vue&type=style&index=0&id=3d11e9bf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d11e9bf",
  null
  
)

export default component.exports