<template>
	<el-dialog
		:visible.sync="dialogVisible"
		width="680px"
		:show-close="false"
		append-to-body
		:modal-append-to-body="false"
		custom-class="dialogBox"
	>
		<div class="forward-view">
			<div class="user-list">
				<div class="search-view">
					<el-input
						v-model="searchValue"
						placeholder="搜索联系人"
						class="searchInput"
					>
						<i slot="suffix" class="icon-sohu-sousuo1"></i>
					</el-input>
				</div>
				<div class="list-view" v-if="computedList.length">
					<div class="item" v-for="(item,index) in computedList" :key="item.id" @click="handleCheck(index)">
						<div class="info">
							<el-avatar :src="item.userAvatar"></el-avatar>
							<div class="name">{{ item.userName }}</div>
						</div>
						<q-checkbox :disabled="userList.filter(v=>v.check).length === 9" v-model="item.check"/>
					</div>
				</div>
				<empty style="margin-top: 20px" content="未搜索到联系人" v-else />
			</div>
			<div class="forward-list">
				<div class="title">发送给</div>
				<div class="select-list" v-if="userList.filter(v=>v.check).length">
					<div class="item" v-for="(item,index) in userList.filter(v=>v.check)">
						<div class="info">
							<el-avatar :src="item.userAvatar"></el-avatar>
							<div class="name">{{ item.userName }}</div>
						</div>
						<i class="icon-sohu-guanbi2" @click="handleDelSelect(item)"></i>
					</div>
				</div>
				<div v-else style="flex: 1;display: flex;align-items: center;justify-content: center">
					<empty style="margin-top: 20px" content="请选择需要发送的联系人" />
				</div>
				<div class="forward-content">
					<div class="content">{{ messageTypeFilter() }}</div>
				</div>
				<div class="handle-view">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="handleForwardSend">发送</el-button>
				</div>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import empty from "@/components/empty.vue";
import { sendWSPush } from "@/utils/websocket";
import { timeStringToDate } from '@/utils/util.js'
export default {
	components: { empty },
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			dialogVisible: false,
			searchValue: "",
			forwardContent: {},
			userList: [],
		};
	},
	computed: {
		userInfo() {
			return this.$store.state.userInfo;
		},
		computedList() {
			if (this.searchValue) {
				return this.userList.filter(v => v.userName.includes(this.searchValue));
			} else {
				return this.userList;
			}
		},
	},
	methods: {
    //判断是否在禁言时间段内
    isforbidTime(timeList){
      if(!timeList) return false
      if(timeList.length == 0) return false
      let timeNum = 0
      timeList.map((item) => {
          console.log(item,99999,timeStringToDate(item["startTime"]),timeStringToDate(item["endTime"]))
					if (new Date() >= timeStringToDate(item["startTime"]) && new Date() <= timeStringToDate(item["endTime"])) {
						timeNum = 0
					} else {
						timeNum += 1;
					}
				})
				if(timeList && timeList.length == timeNum){
          return false
        }else if(timeList && timeNum < timeList.length ){
          return true
        }
    },
		/**
		 * 操作check
		 * @param index
		 */
		handleCheck(index) {
			if (this.computedList.filter(v => v.check).length === 9) return this.$message.info("最多可转发9个好友/群聊");
			this.computedList[index].check = !this.computedList[index].check;
    },
		/**
		 * 操作消息类型文本展示
		 * @returns {string}
		 */
		messageTypeFilter() {
      console.log('文件信息',this.forwardContent)
			switch (this.forwardContent.messageType) {
				case "text":
					return this.forwardContent.body.content;
				case "photo":
					return "[图片]";
				case "video":
					return "[视频]";
				case "share":
					return "[分享]";
				case "file":
					return this.forwardContent.file.fileName ? "[文件]" + this.forwardContent.file.fileName : "[文件]";
			}
		},
		/**
		 * 打开弹窗
		 * @param message
		 */
		async open(message) {
			this.dialogVisible = true;
			this.forwardContent = message;
			this.userList = this.list.map(v => ({ ...v, check: false }));
      
      let res = await this.$http.imGroupJoinList()
      if(res.code == 200){
        this.userList.map((item,index) =>{
          res.data.map((v) =>{
            if(item.receiver.id == v.id && item.sessionType == v.groupType){
              let obj = {...this.userList[index],forbid:v.forbid,forbidTime:v.forbidTime,timeList:v.timeList,check: false}
              this.$set(this.userList,index,obj)
            } 
          })
        })
      }
      console.log('求值',this.userList)
		},
		/**
		 * 操作删除已选中
		 * @param model
		 */
		handleDelSelect(model) {
			const index = this.userList.findIndex(v => v.id === model.id);
			this.userList[index].check = false;
		},
		/**
		 * 操作转发发送消息
		 */
     async handleForwardSend() {
			const forwardList = this.userList.filter(v => v.check);
      // 有禁言内的群聊 跳出
      let isSendError = 0
			forwardList.forEach((item) => {
        let isLeaderAdmin = (item.groupUserPermissionType != 'group_leader' && item.groupUserPermissionType != 'group_admin')
        if(item.forbid && isLeaderAdmin){
          isSendError += 1
          return false
        }  
        if(item.forbidTime && isLeaderAdmin && this.isforbidTime(item.timeList)){
          isSendError += 1
          return false
        }  
        console.log(item.forbid,'----',item.forbidTime,this.$store.state.userInfo.id != item.userId,this.isforbidTime(item.timeList))
				const sendParams = {
					content: this.forwardContent.body.content,
					localId: this.$util.getUuid(),
					messageType: this.forwardContent.messageType,
					sessionType: item.sessionType,
					receiverId: item.receiver.id,
				};
				switch (this.forwardContent.messageType) {
					case "text":
						break;
					case "file":
						sendParams.file = this.forwardContent.file;
						break;
					case "share":
						sendParams.shareType = this.forwardContent.share.type; //分享类型
						sendParams.shareId = this.forwardContent.share.id; //分享id
						sendParams.share = this.forwardContent.share;
						break;
				}
				console.log(sendParams, "sendParams");
				 sendWSPush({
					...sendParams,
				});
				
			});
      console.log('群发结束',isSendError,2222)
      if(!isSendError || isSendError < forwardList.length) this.$message.success("发送完成")
      if(isSendError == forwardList.length) this.$message.error("发送失败")
      this.dialogVisible = false;
		},
	},
};
</script>
<style lang="scss" scoped>

.dialogBox {
  background: #ffffff;
  overflow: hidden;
  padding: 0 !important;

  &.el-dialog {
    border-radius: 16px;
    padding: 0 !important;

    .el-dialog__header {
      padding: 0;

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #3d3d3d;
        // font-size: 16px;
        font-size: 24px;
        font-weight: 500;

        .closeIcon {
          font-size: 32px;
          color: #333333;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .el-dialog__body {
      padding: 0;

      .forward-view {
        width: 100%;
        height: 546px;
        display: flex;
        box-sizing: border-box;

        .user-list {
          width: 300px;
          height: 100%;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          border-right: 1px solid #F3F3F3;
          padding-bottom: 32px;

          .search-view {
            padding: 0 32px;
            box-sizing: border-box;

            .searchInput {
              margin-top: 24px;
              border-radius: 4px;
              height: 36px;
              position: relative;
              box-sizing: border-box;

              .el-input__inner {
                border: 0 solid #000;
                background: #f3f3f3;
                padding-left: 10px;
                padding-right: 40px;

                &::placeholder {
                  color: #999999;
                  font-size: 14px;
                }
              }

              .el-input__suffix {
                .el-input__clear {
                  position: absolute;
                  right: 30px;
                }

                .icon-sohu-sousuo1 {
                  right: 5px;
                  position: absolute;
                  top: 10px;
                  font-size: 20px;
                  color: #5d5d5d;
                  cursor: pointer;

                  &:hover {
                    opacity: 0.8;
                  }
                }
              }
            }
          }

          .list-view {
            flex: 1;
            width: 100%;
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            overflow-y: auto;

            .item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 32px;
              cursor: pointer;

              .info {
                display: flex;
                align-items: center;

                .el-avatar {
                  width: 40px;
                  height: 40px;
                  margin-right: 10px;
                }

                .name {
                  max-width: 138px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }

              .el-checkbox {
                margin-right: 4px;
              }
            }
          }
        }

        .forward-list {
          width: 380px;
          height: 100%;
          padding: 32px 40px 32px 40px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          .title {
            color: #333;
            font-size: 24px;
          }

          .select-list {
            width: 100%;
            flex: 1;
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            overflow-y: auto;

            .item {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .info {
                display: flex;
                align-items: center;

                .el-avatar {
                  width: 40px;
                  height: 40px;
                  margin-right: 10px;
                }

                .name {
                  max-width: 160px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }

              .icon-sohu-guanbi2 {
                color: #999;
                cursor: pointer;
                margin-right: 5px;
              }
            }
          }

          .forward-content {
            width: 100%;
            height: 78px;
            border-top: 1px solid #E7E7E7;
            margin-top: 5px;

            .content {
              font-size: 14px;
              color: #999;
              line-height: 22px;
              margin-top: 10px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3; /* 设置文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .handle-view {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;

            .el-button {
              width: 100px;
              height: 40px;
            }

            .el-button--primary {
              background-color: #FF6C27;
              color: #FFF;
              border: 1px solid #FF6C27;

              &:hover {
                opacity: 0.8;
              }

              &:active {
                border: 1px solid #FF6C27;
                outline: 0;
              }
            }

            .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
              background-color: #F3F3F3;
              color: #999999;
              border: 1px solid #F3F3F3;
            }
          }
        }
      }
    }
  }
}

::v-deep .el-dialog {
  padding: 0 !important;
}
</style>
