import { get, postFile, postJson,deleteApi } from '@/utils/request';

export default {
	// 获取轮播图列表
	getBannerList: (data) => {
		return get('/app/api/xs/sqx_fast/banner/selectBannerList', data);
	},

	// 获取书架列表
	getBookShelfList: (data) => {
		return get('/app/api/xs/sqx_fast/app/courseCollect/selectByUserId', data);
	},

	// 获取首页列表
	getNewRecommend: (data) => {
		return get('/app/api/xs/sqx_fast/app/course/selectCourse', data);
	},

	// 获取分类列表
	getCategoryList: (data) => {
		return get(
			'/app/api/xs/sqx_fast/app/courseClassification/selectClassification',
			data,
		);
	},

	// 获取小说详情
	getBookDetail: (data) => {
		return get('/app/api/xs/sqx_fast/app/course/selectCourseDetailsById', data);
	},

	// 获取评论列表
	getBookCommentList: (data) => {
		return get(
			'/app/api/xs/sqx_fast/app/courseComment/selectCourseComment',
			data,
		);
	},

	// 小说评论
	bookComment: (data) => {
		return postJson(
			'/app/api/xs/sqx_fast/app/courseComment/insertCourseComment',
			data,
		);
	},

	// 加入书架
	addBookShelf: (data) => {
		return postJson(
			'/app/api/xs/sqx_fast/app/courseCollect/insertCourseCollect',
			data,
		);
	},

	// 获取章节列表
	getBookChapterList: (data) => {
		return get('/app/api/xs/sqx_fast/app/course/selectCourseDetailsPage', data);
	},

	// 记录已读章节
	recordReadChapter: (data) => {
		return postJson(
			'/app/api/xs/sqx_fast/app/courseCollect/insertCourseCollect',
			data,
		);
	},

	// 小说点赞
	novelLike: (data) => {
		return postJson('/app/api/xs/sqx_fast/app/up', data);
	},

	// 小说取消点赞
	novelCancelLike: (data) => {
		return postJson('/app/api/xs/sqx_fast/app/deleteUp', data);
	},

	// 章节详情
	getChapterDetail: (data) => {
		return postJson('/app/api/xs/sqx_fast/app/courseDetails/select', data);
	},

	// 支付接口
	payPayment: (data) => {
		return get(`/app/pay/payment`, data);
	},

	// 评论点赞
	 commentLike: (data) => {
		return get('/app/api/xs/sqx_fast/app/courseComment/updateGoodsNum', data);
	},

	// 移除书架小说
	deleteShelfBooks: (array) => { //传入数组
		return deleteApi(`/app/api/xs/sqx_fast/app/courseCollect/deleteCourseCollect/${array.join(',')}`)
	}
};
