<template>
	<div
		:class="[{ rightMsg: itMe }, 'chat-msg-item']"
		@click="messageItemClick"
		:style="styles"
	>
		<div class="chat-msg-item-time" v-if="isShowTime">
			{{ messageCreateTime }}
		</div>
		<!-- 消息提示 -->
		<div
			class="chat-msg-item-time"
			v-if="
				item.messageType == 'command' ||
				(item.messageType == 'msg' && item.type !== 'delete') ||
				item.commandType == 'recall'
			"
		>
			<div>
				{{
					item.commandType == 'recall' && itMe
						? '您撤回了一条消息'
						: item.body.content
				}}
			</div>
		</div>

		<div
			class="chat-msg-item-content"
			v-else
			v-show="!(item.body.err && $store.state.userInfo.id != item.sender.id)"
		>
			<div class="userAvatar" @click="handleToUserDetail(item)">
				<el-avatar
					style="width: 36px; height: 36px"
					:src="item.sender.avatar"
				></el-avatar>
			</div>

			<el-popover
				popper-class="messageMenu"
				placement="bottom"
				trigger="manual"
				v-model="visible"
				disabled
			>
				<div
					class="messageMenuBox"
					@mouseenter="handleMouseEnter"
					@mouseleave="handleMouseLeave"
				>
					<div @click="messageMenuClick('撤回')">
						<i class="icon-sohu-chehui"></i>
						<p>撤回</p>
					</div>
					<!-- <div @click="messageMenuClick('回复')">
						<i class="icon-sohu-xiaoxi1"></i>
						<p>回复</p>
					</div>
					<div @click="messageMenuClick('删除')">
						<i class="icon-sohu-shanchu"></i>
						<p>删除</p>
					</div> -->
				</div>
				<div
					slot="reference"
					class="messageBody"
					@mouseenter="handleMouseEnter"
					@mouseleave="handleMouseLeave"
					ref="messageBodyRef"
				>
					<el-tooltip effect="dark" :content="item.body.err" placement="top">
						<i
							class="el-icon-warning"
							v-if="item.body.err"
							@click="errorClick"
						></i>
					</el-tooltip>
					<!-- 群聊显示昵称和角色 -->
					<div
						class="senderName"
						v-if="['group', 'groupTask'].includes(item.sessionType)"
					>
						{{ item.sender.name }}
						<span class="roleTag" v-if="roleName">
							{{ roleName }}
						</span>
					</div>
					<!-- 图片 -->
					<div v-if="item.messageType == 'photo'" class="photo">
						<el-image
							@load="imageLoaded"
							:src="item.body.content"
							:preview-src-list="[item.body.content]"
						>
						</el-image>
					</div>
					<!-- 视频 -->
					<div
						v-else-if="item.messageType == 'video'"
						class="video"
						@click="videoView"
					>
						<video :src="item.body.content"></video>
						<i class="icon-sohu-bofang"></i>
					</div>
					<!-- 语音 -->
					<div
						v-else-if="item.messageType == 'voice'"
						:class="[
							{ smallVoice: item.body.duration > 10 },
							{ mediumVoice: item.body.duration > 50 },
							'voice',
						]"
						@click="playAudio"
					>
						<i class="icon-sohu-yuyinbofang playing" v-if="!playing"></i>
						<el-image
							class="playing"
							v-else
							:src="require('@/assets/images/chat/playing.gif')"
						></el-image>
						<span class="duration"> {{ item.body.duration }}" </span>
					</div>
					<!-- 分享卡片 -->
					<div v-else-if="item.messageType == 'share'" class="share">
						<!--    task("task", "任务"),
										taskGuide("taskGuide", "任务咨询"),
										good("good", "商品"),
										article("article", "图文"),
										video("video", "视频"),
										person("person", "分享名片"),
										invite("invite", "邀请"),
										goodWindow("goodWindow", "商品橱窗"),
										answer("answer", "回答"),
										question("question", "问题"), -->
						<!-- 任务 -->
						<div
							v-if="['taskGuide'].includes(item.share.type)"
							class="share_taskGuide"
							@click="shareCardClick"
						>
							<div class="share_taskGuide_content">
								<el-image
									:src="require('@/assets/images/chat/taskImg.png')"
								></el-image>
								<div class="right_taskGuide_content">
									<div class="title line-2">
										{{ item.share.title }}
									</div>
									<div class="price">
										<span>￥</span>
										{{ item.share.amount }}
									</div>
								</div>
							</div>
							<div class="share_taskGuide_user">
								来自{{ item.sender.name }}的分享
							</div>
						</div>
						<!-- 视频 -->
						<div
							v-else-if="
								['Video', 'task', 'article', 'playlet'].includes(
									item.share.type,
								)
							"
							class="share_video"
							@click="shareCardClick"
						>
							<div class="content_box">
								<div class="content_text">
									<div class="content_title line-2">
										<span v-if="item.share.type == 'task'">
											{{ item.share.name }}邀请您加入任务“{{
												item.share.title
											}}”快乐赚钱吧
										</span>
										<span v-else>快来看看作品「{{ item.share.title }}」</span>
									</div>
									<div class="content_userData">
										<el-avatar :size="20" :src="item.share.avatar"></el-avatar>
										<div class="username line-1">{{ item.share.name }}</div>
									</div>
								</div>
								<el-image
									:src="
										item.share.type == 'task'
											? require('@/assets/images/chat/taskShare.png')
											: item.share.shareParam
											? JSON.parse(item.share.shareParam).videoThumbnail
											: require('@/assets/images/logo_x2.png')
									"
									fit="cover"
								></el-image>
							</div>
							<div class="share_title">狐少少</div>
						</div>
						<!-- 名片 -->
						<div
							v-else-if="item.share.type == 'person'"
							class="share_person"
							@click="shareCardClick"
						>
							<div class="userData">
								<el-image
									class="leftImg"
									:src="item.share.avatar"
									fit="cover"
								></el-image>
								<div class="username line-1">
									{{ item.share.name }}
								</div>
							</div>
							<div class="share_user">个人名片</div>
						</div>

						<!-- 商品 -->
						<div
							v-else-if="['good'].includes(item.share.type)"
							class="share_video"
							@click="shareCardClick"
						>
							<div class="content_box">
								<div class="content_text">
									<div class="content_title line-2">
										快来看看商品「{{ item.share.title }}」
									</div>
									<div class="content_userData">
										<el-avatar
											:size="20"
											:src="item.share.shareUserAvatar"
										></el-avatar>
										<div class="username line-1">
											{{ item.share.shareUserName }}
										</div>
									</div>
								</div>
								<el-image :src="item.share.coverImage" fit="cover"></el-image>
							</div>
							<div class="share_title">狐少少</div>
						</div>

						<!-- 邀请 -->
						<div
							v-else-if="['invite'].includes(item.share.type)"
							class="share_video"
							@click="shareCardClick"
						>
							<div class="content_box">
								<div class="content_text">
									<div class="content_title line-2">
										{{ item.share.shareUserName }}邀请您加入狐少少一起做任务赚钱
									</div>
									<div class="content_userData">
										<el-avatar
											:size="20"
											:src="item.share.shareUserAvatar"
										></el-avatar>
										<div class="username line-1">
											{{ item.share.shareUserName }}
										</div>
									</div>
								</div>
								<el-image
									:src="require('@/assets/images/chat/inviteShare.png')"
									fit="cover"
								></el-image>
							</div>
							<div class="share_title">狐少少</div>
						</div>

						<!-- 未处理的类型 -->
						<div class="chat-msg-text" v-html="content" v-else></div>
					</div>

					<!-- 文件 -->
					<div
						v-else-if="['file'].includes(item.messageType)"
						class="file-card"
						@click="handleToFileDetail"
						@contextmenu.prevent.stop="handleMessage"
					>
						<div class="info-view">
							<div class="title">{{ item.file.fileName }}</div>
							<div class="size">
								{{ util.fileSizeFilter(item.file.fileSize) }}
							</div>
						</div>
						<el-image :src="fileIconFilter(item.file.fileName)"></el-image>

						<div
							class="handle-view"
							v-if="!item.body.err && item.file.status !== 'expired'"
						>
							<i
								class="el-icon-loading"
								v-if="item.file.status && item.file.status === 'loading'"
							></i>
							<i
								class="icon-sohu-xiazai1"
								v-else
								@click.stop="handleDownload(item)"
							></i>
						</div>
						<div class="file-tip" v-if="item.file.status === 'expired'">
							文件已过期或已被清理
						</div>
					</div>

					<!-- @contextmenu.prevent.stop="handleMessage"
						@click="closeContextMenu" -->
					<!-- 纯文本 -->
					<div
						class="chat-msg-text"
						v-html="content"
						@contextmenu.prevent.stop="handleMessage($event)"
						v-else
					></div>
				</div>
			</el-popover>
		</div>
		<!-- 个人名片弹窗 -->
		<businessCardDialog ref="businessCardDialogRef"></businessCardDialog>
	</div>
</template>
<script>
import BusinessCardDialog from '@/views/chat/components/userDialog/businessCardDialog.vue';
import util from '@/utils/util';
import forwardDialog from '@/views/chat/components/forwardDialog.vue';
import baseUrl from '@/utils/baseUrl';

export default {
	components: { BusinessCardDialog, forwardDialog },
	props: ['item', 'prev', 'detail', 'role', 'itemInfo', 'styles'],
	data() {
		return {
			audio: '',
			playing: false,
			visible: false,
			timer: null,
			activeChatId: '',
		};
	},
	computed: {
		util() {
			return util;
		},
		itMe() {
			return this.item.sender.id == this.$store.state.userInfo.id;
		},
		// 消息类容
		content() {
			let str = '';
			if (this.item.messageType == 'text') {
				var httpReg =
					/(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi;
				str = this.item.body.content.replace(httpReg, function (httpText) {
					let url = '';
					if (!httpText.includes('http')) {
						url = '//' + httpText;
					} else {
						url = httpText;
					}
					return "<a href='" + url + "' target='_blank'>" + httpText + '</a>';
				});
			} else {
				str = '[该消息暂未支持，请在App内查看]';
			}
			return str;
		},
		roleName() {
			switch (this.item.sender.role) {
				case 'group_leader':
					return '群主';
				case 'group_admin':
					return '管理员';
			}
		},

		// 消息时间
		messageCreateTime() {
			return this.$util.toTimeText(this.item.createTime, false);
		},

		// 是否显示时间
		isShowTime() {
			if (this.prev) {
				return this.item.createTime - this.prev.createTime > 1000 * 60 * 5;
			} else {
				return true;
			}
		},
	},
	beforeDestroy() {
		if (this.audio) {
			this.playing = false;
			this.audio.pause();
			this.audio = null;
		}
	},
	methods: {
		messageItemClick() {
			this.$emit('messageItemClick', this.item);
		},
		//操作消息
		handleMessage(event) {
			const x = event.clientX;
			const y = event.clientY;
			this.$emit('contextmenu', this.item, x, y);
		},

		//转发操作
		forwardOperate() {
			this.$emit('forward', this.item);
		},

		/**
		 * 前往文件详情
		 */
		handleToFileDetail() {
			if (this.item.file.status === 'expired')
				return this.$message.info('文件已过期不可预览');
			const strList = this.item.file.fileName.split('.');
			const fileType = strList[strList.length - 1];
			console.log(fileType, 'fileType');
			const testFileSrc = {
				doc: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/8e4262249ea14fdb8fcf0f38664e9312_0x0.doc',
				docx: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/7d9c3e7a2379423fbd68cf0bbf29ebaa_0x0.docx',
				xls: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/d03cc27c758841839af03dcb368028e8_0x0.xls',
				xlsx: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/abc87d28df9644de8e0330fcbc75cac8_0x0.xlsx',
				ppt: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/4c72c8b65ec5463ebacaf8f666ebf1e0_0x0.ppt',
				pptx: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/c8200200591f40fb819773448eead496_0x0.pptx',
				pdf: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/11/28/c1615ea1e926433990c87bd563e3791d_0x0.pdf',
			};
			const filePreviewUrl =
				'https://view.officeapps.live.com/op/view.aspx?spm=a2c6h.12873639.article-detail.4.18756556S0kKlX&src=';
			console.log(filePreviewUrl + testFileSrc[fileType]);
			if (process.env.VUE_APP_FLAG === 'pro') {
				window.open(fileType === 'pdf' ? this.item.body.content : filePreviewUrl + this.item.body.content);
			} else {
				window.open(
					fileType === 'pdf'
						? testFileSrc[fileType]
						: filePreviewUrl + testFileSrc[fileType],
				);
			}
		},
		/**
		 * 操作下载文件
		 * @param model
		 */
		handleDownload(model) {
			fetch(
				`${baseUrl.BASE_URL}/resource/oss/download/url?pathUrl=${model.body.content}`,
			)
				.then( async (response) => {
					console.log(response);
					// 检查HTTP响应状态码
					if (response.status === 200) {
						const blob = await response.blob();
						if (blob.type === "application/octet-stream") {
							return blob;
						} else {
							throw new Error('File is not available or has expired.');
						}
					} else {
						throw new Error('File is not available or has expired.');
					}
				})
				.then((blob) => {
					// 进行文件完整性检查（例如哈希值验证）
					// 如果文件损坏或过期，抛出异常
					// 否则，执行下载操作
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = model.file.fileName;
					link.target = '_blank';
					link.click();
				})
				.catch((error) => {
					console.log(error, 'catch');
					this.$set(this.item.file, 'status', 'expired');
					console.log(this.item);
				});
		},
		/**
		 * 文件图标过滤器
		 * @param fileName
		 * @returns {string}
		 */
		fileIconFilter(fileName) {
			if (!fileName) return '';
			const strList = fileName.split('.');
			const fileType = '.' + strList[strList.length - 1];
			if (['.doc', '.docx'].includes(fileType)) {
				return require('@/assets/images/chat/file-word.png');
			} else if (['.xls', '.xlsx'].includes(fileType)) {
				return require('@/assets/images/chat/file-xlsx.png');
			} else if (['.ppt', '.pptx'].includes(fileType)) {
				return require('@/assets/images/chat/file-ppt.png');
			} else if (['.pdf'].includes(fileType)) {
				return require('@/assets/images/chat/file-pdf.png');
			}
		},
		/**
		 * 操作前往用户详情
		 * @param model
		 */
		handleToUserDetail(model) {
			if (this.itMe) return;
			const isGroup = ['group', 'groupTask'].includes(this.detail.sessionType); //是否为群聊
			const isAuth = ['group_leader', 'group_admin'].includes(this.role); //是否为管理员 或 群主
			const isAddFriend = this.detail.addFriend; //是否可进行相互添加好友
			if (isGroup && isAddFriend && !isAuth) {
				this.$message.info('当前群聊已设置禁止添加好友');
			} else {
				this.$refs.businessCardDialogRef.open(model.sender.id);
			}
		},

		// 图片加载完成
		imageLoaded() {
			this.$emit('imageLoad');
		},

		// 视频预览
		videoView() {
			window.open(this.item.body.content);
		},

		// 语音播放
		playAudio() {
			if (!this.audio) {
				this.audio = new Audio(this.item.body.content);
			}
			//监听语音
			this.audio.addEventListener('ended', () => {
				this.playing = false;
				this.audio.pause();
				console.log('Audio ended');
			});
			if (!this.playing) {
				this.playing = true;
				this.audio.play();
			}
		},

		// 鼠标右键事件
		showMenu() {
			if (this.itMe) {
				clearTimeout(this.timer);
				this.visible = true;
			}
		},

		// 鼠标移入菜单
		handleMouseEnter() {
			if (this.visible) {
				clearTimeout(this.timer);
			}
		},

		// 鼠标移出菜单
		handleMouseLeave() {
			if (this.visible) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.visible = false;
				}, 1000);
			}
		},

		// 点击菜单
		messageMenuClick(type) {
			clearTimeout(this.timer);
			this.visible = false;
		},

		// 分享卡片
		shareCardClick() {
			// if (1 === 1) return this.$emit("forward", this.item);
			let link;
			switch (this.item.share.type) {
				case 'task':
					break;
				case 'taskGuide':
					link = this.$router.resolve({
						path: '/makeMoney/taskDetails',
						query: {
							taskNumber: this.item.share.content,
							source: '任务广场',
						},
					});
					break;
				case 'Video':
					link = this.$router.resolve({
						path: '/videoShare',
						query: {
							id: this.item.share.id,
						},
					});
					break;
				case 'article':
					link = this.$router.resolve({
						path: '/imageTextDetail',
						query: {
							id: this.item.share.id,
						},
					});
					break;
				case 'person':
					link = this.$router.resolve({
						path: '/othersHome',
						query: {
							userId: this.item.share.id,
						},
					});
					break;
				case 'playlet':
					link = this.$router.resolve({
						path: '/shortPlayDetail',
						query: {
							episodeRelevance: this.item.share.episodeRelevance,
						},
					});
					break;
			}
			link && window.open(link.href, '_blank');
		},

		// 消息报错，重新发送
		errorClick() {
			let sendParams = {};
			switch (this.item.messageType) {
				case 'share':
					// sendParams.shareType = this.item.share.type;
					sendParams.shareId = this.item.share.id;
					// sendParams.shareParam = this.item.share.shareParam;
					sendParams.share = {
						amount: this.item.share.amount,
						content: this.item.share.content,
						id: this.item.share.id,
						shareUserId: this.$store.state.userInfo.id,
						title: this.item.share.title,
						type: this.item.share.type,
						userId: this.item.share.userId,
					};
					break;
			}
			this.$emit('errorClick', {
				localId: this.item.localId,
				messageType: this.item.messageType,
				content: this.item.body.content,
				...sendParams,
			});
		},
	},
};
</script>
<style lang="scss">
.chat-msg-item {
	margin-top: 24px;

	.chat-msg-item-time {
		text-align: center;
		margin-bottom: 20px;
		color: #999;
		font-size: 12px;
	}

	.chat-msg-item-content {
		display: flex;

		.userAvatar {
			width: 36px;
			height: 36px;
			margin-right: 8px;
			margin-left: 20px;
			cursor: pointer;
		}

		.messageBody {
			position: relative;

			.el-icon-warning {
				position: absolute;
				left: -5px;
				top: 50%;
				transform: translate(-100%, -50%);
				color: #f56c6c;
				font-size: 24px;
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}
			}

			.senderName {
				color: #999999;
				font-size: 12px;
				margin-bottom: 5px;

				.roleTag {
					background: #d9e1ff;
					color: #4787f0;
					font-size: 12px;
					padding: 1px 8px;
					border-radius: 2.86px;
					margin-left: 7px;
					line-height: 18px;
					display: inline-block;
				}
			}

			.file-card {
				background: #fff;
				border-radius: 4px;
				padding: 12px 14px 14px 12px;
				box-sizing: border-box;
				width: 290px;
				height: 84px;
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				position: relative;

				.info-view {
					width: 190px;

					.title {
						color: #1a1a1a;
						font-size: 14px;
						line-height: 18px;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}

					.size {
						color: #999999;
						font-size: 10px;
						line-height: 16px;
						margin-top: 7px;
					}
				}

				.el-image {
					width: 56px;
					height: 56px;
				}

				.handle-view {
					position: absolute;
					top: 50%;
					right: -27px;
					transform: translate(0, -50%);
					font-size: 20px;

					.icon-sohu-xiazai1 {
						color: #999999;
					}
				}
				.file-tip {
					position: absolute;
					bottom: -16px;
					font-size: 12px;
					color: red;
				}
			}

			.chat-msg-text {
				border-radius: 4px;
				padding: 8px 12px;
				color: #1a1a1a;
				font-size: 14px;
				box-sizing: border-box;
				background-color: #fff;
				line-height: 20px;
				max-width: 246px;
				display: inline-block;
				text-wrap: wrap;
				white-space: pre-wrap;
				word-break: break-all;
				word-wrap: break-word;

				a {
					color: #4787f0;
					text-decoration: underline;

					&:hover {
						opacity: 0.8;
					}
				}
			}

			.photo {
				.el-image {
					border-radius: 5px;
					overflow: hidden;

					img {
						max-width: 240px;
						max-height: 180px;
						width: auto;
						height: auto;
						object-fit: fill;
					}
				}
			}

			.video {
				width: 135px;
				height: 180px;
				cursor: pointer;
				position: relative;

				video {
					width: 100%;
					height: 100%;
					border-radius: 5px;
					object-fit: cover;
				}

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: #fff;
					opacity: 0.8;
					font-size: 60px;
				}
			}

			.voice {
				background: #fff;
				border-radius: 4px;
				display: flex;
				align-items: center;
				padding: 10px 14px;
				color: #1a1a1a;
				font-size: 12px;
				cursor: pointer;

				i {
					font-size: 16px;
				}

				.duration {
					margin: 0 5px;
				}

				&.smallVoice {
					width: 146px;
				}

				&.mediumVoice {
					width: 246px;
				}
			}

			.share {
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}

				.share_taskGuide {
					background: #fff;
					border-radius: 4px;
					padding: 12px;
					box-sizing: border-box;
					width: 295px;
					cursor: pointer;

					.share_taskGuide_content {
						margin-bottom: 12px;
						display: flex;

						.el-image {
							border-radius: 5px;
							min-width: 64px;
							height: 64px;
							margin-right: 12px;
						}

						.right_taskGuide_content {
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							.title {
								color: #1a1a1a;
								font-size: 14px;
								margin-bottom: 5px;
								line-height: 20px;
							}

							.price {
								color: #f6685d;
								font-weight: 600;
								font-size: 20px;

								span {
									font-size: 12px;
								}
							}
						}
					}

					.share_taskGuide_user {
						border-top: 1px solid #d8d8d8;
						color: #969696;
						font-size: 12px;
						padding-top: 8px;
					}
				}

				.share_video {
					background: #fff;
					border-radius: 4px;
					width: 295px;
					height: 121px;
					padding: 12px;
					box-sizing: border-box;

					.content_box {
						display: flex;
						justify-content: space-between;

						.content_text {
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							.content_title {
								font-size: 13px;
								color: #3d3d3d;
								line-height: 18px;
								margin-right: 50px;
							}

							.content_userData {
								display: flex;
								align-items: center;

								.username {
									color: rgba(0, 0, 0, 0.6);
									font-size: 10px;
									margin-left: 5px;
								}
							}
						}

						.el-image {
							border-radius: 5px;
							min-width: 64px;
							height: 64px;
						}
					}

					.share_title {
						border-top: 1px solid #d8d8d8;
						color: #969696;
						font-size: 12px;
						padding-top: 8px;
						margin-top: 14px;
					}
				}

				.share_person {
					background: #fff;
					border-radius: 4px;
					width: 295px;
					height: 121px;
					padding: 12px;
					box-sizing: border-box;

					.userData {
						display: flex;
						align-items: center;
						flex: 1;

						.leftImg {
							border-radius: 5px;
							min-width: 64px;
							height: 64px;
							margin-right: 12px;
						}

						.username {
							color: #1a1a1a;
							font-size: 14px;
						}
					}

					.share_user {
						border-top: 1px solid #d8d8d8;
						color: #969696;
						font-size: 12px;
						padding-top: 8px;
						margin-top: 14px;
					}
				}
			}
		}
	}

	&.rightMsg {
		.chat-msg-item-content {
			justify-content: flex-end;
			position: relative;
			.userAvatar {
				order: 1;
				margin-left: 8px;
				margin-right: 20px;
			}

			.messageBody {
				position: relative;
				.senderName {
					display: none;
				}

				.chat-msg-text {
					background: #95ec69;
					cursor: pointer;
				}

				.file-card {
					.handle-view {
						left: -27px;
					}
				}

				.voice {
					justify-content: flex-end;
					background: #95ec69;

					.playing {
						order: 1;
						transform: scaleX(-1);
					}
				}
			}
		}
	}
}

.messageMenu {
	padding: 16px 16px 12px !important;
	width: 40px;
	min-width: 40px !important;
	border-radius: 10px !important;

	.messageMenuBox {
		display: grid;
		column-gap: 24px;
		grid-template-columns: repeat(1, 1fr);
		text-align: center;
		color: #333333;

		div {
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}

		i {
			font-size: 16px;
		}

		p {
			font-size: 13px;
		}
	}
}
</style>
