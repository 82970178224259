<
<template>
	<div class="video-box" @mousewheel="handleMousewheel">
		<el-carousel
			style="height: 100%"
			direction="vertical"
			trigger="click"
			:autoplay="false"
			ref="carousel"
			@change="videoChange"
			indicator-position="none"
			:initial-index="currentIndex"
		>
			<el-carousel-item
				v-for="(item, index) in videoList"
				:key="index"
				:name="'video' + index"
			>
				<div
					class="video-main"
					v-loading="!videoTime"
					v-if="currentIndex == index"
				>
					<video
						ref="videos"
						class="video"
						:src="item.videoUrl"
						@ended="handleEnded(index)"
						@loadedmetadata="getVideoDuration"
						@timeupdate="onTimeUpdate"
						@click="playVideo"
						:muted="isSilent"
						:loop="isLoop"
					></video>
				</div>
			</el-carousel-item>
		</el-carousel>
		<div class="playBox">
			<i class="icon-sohu-play" @click="playVideo" v-if="isPlay"></i>
		</div>
		<div class="control-box">
			<el-slider
				:max="sliderTime"
				v-model="currentSliderTime"
				:show-tooltip="false"
				@change="seekTo"
			></el-slider>
			<div class="operation-btn">
				<div class="left-box">
					<i class="icon-sohu-play" @click="playVideo" v-if="isPlay"></i>
					<i class="icon-sohu-zanting" @click="playVideo" v-if="!isPlay"></i>
					{{ currentTime }}/{{ videoTime }}
				</div>
				<div class="right-box" @click="playVolume">
					<i class="icon-sohu-yinliang" v-show="!isSilent"></i>
					<i class="icon-sohu-jingyin" v-show="isSilent"></i>
				</div>
			</div>
		</div>
		<div class="person-info">
			<p class="name">@{{ videoList[currentIndex].userName }}</p>
			<p class="title">{{ videoList[currentIndex].title }}</p>
			<div
				class="promotion-main"
				v-if="
					videoList[currentIndex].relation ||
					videoList[currentIndex].episodeRelevance
				"
			>
				<!-- 短剧合集 -->
				<div
					class="shortplay-wrapper"
					v-if="videoList[currentIndex].episodeRelevance"
					@click="goDetailShortPlay"
				>
					<span class="text"
						><span class="type-name"
							>合集:{{ videoList[currentIndex].playletTitle }}</span
						>｜{{ episodeCount }}集全</span
					>
				</div>
				<showcaseWindow
					:relation="videoList[currentIndex].relation"
					type="video"
					v-if="videoList[currentIndex].relation"
				></showcaseWindow>
			</div>
		</div>
		<shortPlayDetail
			ref="shortPlayDetail"
			:episodeRelevance="videoList[currentIndex].episodeRelevance"
			@close="handleShortPlayClose"
		></shortPlayDetail>
	</div>
</template>
<script>
import showcaseWindow from '@/components/showcaseWindow.vue';
import shortPlayDetail from '@/views/content/detail/shortPlayDetail.vue';
export default {
	props: {
		videoList: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Number,
			default: () => {
				return 0;
			},
		},
		total: {
			type: Number,
			default: () => {
				return 0;
			},
		},

		//是否开启循环播放
		isLoop: {
			type: Boolean,
			default: false,
		},
	},
	components: { showcaseWindow, shortPlayDetail },
	data() {
		return {
			sliderTime: null,
			videoTime: null,
			currentTime: '00:00:00',
			currentSliderTime: 0,
			isPlay: true, //是否播放
			isSilent: true, //是否静音
			currentIndex: 0,
			pageNum: 1,
			episodeCount: 0,
		};
	},
	computed: {
		videoContext() {
			return this.$refs.videos[0];
		},
	},
	mounted() {
		this.currentIndex = this.value;
		this.$refs.carousel?.setActiveItem(this.currentIndex);
		this.getVideo();
		this.$nextTick(() => {
			this.prevNext();
		});
	},
	methods: {
		prevNext() {
			// // 监听keydown事件
			document.addEventListener('keydown', (event) => {});
			// 监听keyup事件
			document.addEventListener('keyup', (event) => {
				if (event.key == 'ArrowDown') {
					this.handleKeyDow();
				} else if (event.key == 'ArrowUp') {
					this.handleKeyUp();
				}
			});
			let startNumY;
			document.addEventListener('mousedown', (eve) => {
				startNumY = eve.offsetY;
			});
			document.addEventListener('mouseup', (eve) => {
				if (eve.offsetY - startNumY > 50) {
					this.handleKeyUp();
				} else if (eve.offsetY - startNumY < -50) {
					this.handleKeyDow();
				}
			});
		},
		//获取视频的总集数 只有短剧
		getVideo() {
			if (this.videoList[this.currentIndex].episodeRelevance) {
				this.$http
					.PlayletInfo(this.videoList[this.currentIndex].episodeRelevance)
					.then((res) => {
						this.episodeCount = res.data.episodeCount;
					});
			}
		},
		//获取视频的总长度
		getVideoDuration(event) {
			this.sliderTime = Math.floor(event.target.duration);
			this.videoTime = this.$util.dealTime(Math.floor(event.target.duration));
			this.playVideo();
		},
		// 鼠标拖拽
		seekTo(time) {
			this.currentSliderTime = time;
			this.$refs.videos[0].currentTime = time;
		},
		// 时间更新
		onTimeUpdate(event) {
			this.currentSliderTime = event.target.currentTime;
			this.currentTime = this.$util.dealTime(
				Math.floor(event.target.currentTime),
			);
		},
		// 当视频结束时
		handleEnded() {
			this.currentTime = '00:00:00';
			this.currentSliderTime = 0;
			this.isPlay = true;
			this.arrowClick('down');
		},

		// 点击上下切换按钮
		arrowClick(val) {
			if (val === 'down') {
				this.$refs.carousel.next();
			} else {
				this.$refs.carousel.prev();
			}
		},

		// 鼠标滚轮事件
		handleMousewheel(event) {
			this.throttleMousewheel(event);
		},

		// 节流函数
		throttleMousewheel: (function () {
			let lastExecution = 0;
			const delay = 1000; // 设置节流延迟时间为200毫秒
			return function (event) {
				const now = Date.now();
				if (now - lastExecution >= delay) {
					lastExecution = now;
					if (event.deltaY < 0) {
						this.$refs.carousel.prev();
					} else {
						this.$refs.carousel.next();
					}
				}
			};
		})(),
		//键盘事件
		handleKeyDow() {
			this.$refs.carousel.next();
		},
		handleKeyUp() {
			this.$refs.carousel.prev();
		},
		// 播放视频
		playVideo() {
			if (this.$refs.videos[0].paused) {
				this.$refs.videos[0].play();
			} else {
				this.$refs.videos[0].pause();
			}
			this.isPlay = !this.isPlay;
		},

		// 设置音量
		playVolume() {
			if (this.videoContext.volume == 0) {
				this.videoContext.volume = 1;
				this.isSilent = false;
			} else {
				this.videoContext.volume = 0;
				this.isSilent = true;
			}
		},

		// 视频切换时调用
		videoChange(nVal, oVal) {
			this.currentIndex = nVal;
			this.isPlay = true;
			this.$emit('input', nVal);
			if (nVal >= this.videoList.length - 3) {
				if (this.total > this.videoList.length) {
					this.pageNum++;
					this.$emit('getVideoList', this.pageNum);
				}
			}
		},
		// 跳转短剧详情
		goDetailShortPlay() {
			this.$refs.shortPlayDetail.init();
			this.$refs.videos[0].pause();
			this.isPlay = false;
		},
		// 短剧详情关闭后视频恢复播放
		handleShortPlayClose() {
			this.$refs.videos[0].play();
		},
	},
};
</script>
<style lang="scss" scoped>
.video-box {
	background: #141414;
	overflow-y: auto;
	position: relative;
	color: #fff;
	width: 100%;
	height: 100%;
	.video-main {
		display: flex;
		justify-content: center;
		z-index: 10;
		height: 100%;
		width: 608px;
	}
	.playBox {
		position: absolute;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
		z-index: 999;

		i {
			font-size: 40px;
			color: #fff;
		}
	}
	:deep(.el-slider__runway) {
		background: rgba(255, 255, 255, 0.5);
		margin: 0;
	}
	:deep(.el-slider__bar) {
		background: #fff;
	}
	:deep(.el-slider__button) {
		display: none;
	}
	.control-box {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		z-index: 999;
		.operation-btn {
			height: 54px;
			display: flex;
			align-items: center;
			padding: 0 20px 0 24px;
			.right-box {
				margin-left: auto;
				margin-right: 20px;
			}
		}
	}
	:deep(.el-carousel__container) {
		height: 100%;
		width: 608px;
	}
	:deep(.el-carousel--vertical) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.active {
		background-color: #ccc;
	}
	.person-info {
		position: absolute;
		left: 20px;
		bottom: 60px;
		font-size: 18px;
		line-height: 40px;
		z-index: 999;
		.name {
			font-size: 24px;
		}
		.promotion-main {
			margin-bottom: 10px;
			.shortplay-wrapper {
				height: 40px;
				line-height: 40px;
				border-radius: 8px;
				background: rgba(84, 84, 84, 0.8);
				padding: 0 10px;
				color: #ffffff;
				font-size: 18px;
				position: relative;
				z-index: 999;
				width: max-content;
			}
		}
	}
	:deep(.el-slider__button-wrapper) {
		display: none;
	}
}
</style>
