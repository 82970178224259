<template>
	<dialogBox appendToBody :showHeader="false" v-model="dialogVisible">
		<div class="businessCardDialogBoxContent">
			<div class="userData">
				<el-avatar :src="details.userAvatar"></el-avatar>
				<div class="userInfoBox">
					<div class="userName">{{ details.alias || details.nickName }}</div>
					<div>昵称：{{ details.nickName }}</div>
					<div>狐少少号：{{ details.userName }}</div>
				</div>
			</div>
			<div>
				<div class="rowBox" @click="setRemarksClick" v-if="details.meFriend">
					<span> 备注和标签 </span>
				</div>
				<div class="rowBox" v-if="!isMe">
					<span @click="othersHOme">TA的空间</span>
				</div>
			</div>

			<div class="bottom_btn">
				<button @click="dialogVisible = false">取消</button>
				<button @click="handleConfirm" v-if="!details.meFriend">{{ details.meFriend ? '去聊天' : '添加好友' }}</button>
			</div>
		</div>

		<!-- 申请添加好友弹窗 -->
		<ApplicationDialog ref="ApplicationDialogRef"></ApplicationDialog>

		<setRemarksDialog ref="setRemarksDialogRef"></setRemarksDialog>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import setRemarksDialog from './setRemarksDialog.vue';
import ApplicationDialog from "@/views/chat/components/userDialog/ApplicationDialog.vue";
export default {
	components: { ApplicationDialog, dialogBox, setRemarksDialog },
	data() {
		return {
			dialogVisible: false,
			details: {},
		};
	},
	inject: {
		close: { value: 'close', default: null },
		imGroupSubList: { value: 'imGroupSubList', default: null },
		groupAdminList: { value: 'groupAdminList', default: null },
		imGroupForbidList: { value: 'imGroupForbidList', default: null },
		switchGroupChat: { value: 'switchGroupChat', default: null },
	},
	provide() {
		return {
			getAppFriends: this.getAppFriendsDetails,
		};
	},
	computed:{
		isMe(){
			return this.details.friendId == this.$store.state.userInfo.id
		},
	},
	mounted() {},
	methods: {
		handleConfirm() {
			if (this.details.meFriend) {
				this.dialogVisible = false;
				let messageList = this.$store.state.messageList;
				let userId = this.details.friendId || this.details.id;
				let data = {
					receiver: {
						avatar: this.details.userAvatar || this.details.logo,
						id: userId,
						name: this.details.userName || this.details.name,
					},
					sessionType: this.details.userAvatar ? 'single' : 'group',
					userAvatar: this.details.userAvatar || this.details.logo,
					userName: this.details.userName || this.details.name,
				};
				if (
					this.$store.state.messageList.every(
						(item) => item.receiver.id !== userId,
					)
				) {
					console.log(data);
					messageList.unshift(data);
				}
				this.close(data);
			} else {
				this.$refs.ApplicationDialogRef.open(this.details.friendId);
			}
		},

		open(id) {
			this.dialogVisible = true;
			this.getAppFriendsDetails(id);
		},

		// 好友详情
		async getAppFriendsDetails(id) {
			const res = await this.$http.appFriendsDetails(id);
			console.log(res);
			this.details = res.data;
		},

		// 设置备注
		setRemarksClick() {
			console.log(this.details);
			this.$refs.setRemarksDialogRef.open(this.details.friendId);
		},

		// 他的空间
		othersHOme() {
			let link = this.$router.resolve({
				path: '/othersHome',
				query: {
					userId: this.details.friendId,
				},
			});
			window.open(link.href, '_blank');
		},
	},
};
</script>
<style lang="scss">
.businessCardDialogBoxContent {
	.userData {
		display: flex;
		margin-bottom: 16px;
		.el-avatar {
			width: 60px;
			height: 60px;
			margin-right: 16px;
		}
		.userInfoBox {
			color: #999999;
			font-size: 14px;
			line-height: 22px;
			.userName {
				color: #3d3d3d;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
			}
		}
	}
	.rowBox {
		width: 100%;
		height: 54px;
		border-top: 1px solid #e7e7e7;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		&:last-child {
			border-bottom: 1px solid #e7e7e7;
		}
		span {
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.bottom_btn {
		margin-top: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			height: 40px;
			width: 100px;
			background: #f3f3f3;
			font-size: 16px;
			font-weight: 500;
			cursor: pointer;
			border-radius: 4px;
			color: #3d3d3d;
			&:hover {
				opacity: 0.8;
			}
			& + button {
				margin-left: 16px;
				background: $theme-color;
				color: #ffffff;
			}
		}
	}
}
</style>
